.dialog-container .dialog-component {
  min-width: 300px;
}
.dialog-container .modal {
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.dialog-container .modal-content {
  border-radius: 0;
  padding: 10px 10px;
}
.dialog-container .modal-body {
  font-family: overlock-regular;
  font-size: 1.2em;
}
.dialog-container .modal-header {
  border-bottom: 0;
}
.dialog-container .modal-header .modal-title {
  font-family: overlock-black;
  font-size: 1.4em;
}
.dialog-container .modal-footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end !important;
  border-top: 0;
}
.dialog-container .modal-footer button:last-child {
  margin-left: 10px;
}
.dialog-container .btn-disabled {
  opacity: 0.7;
}
.dialog-open {
  overflow: hidden;
}
