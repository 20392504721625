/* USAGE:
@import '... /responsive.less';
@media @phone-up { ... }
@media @phone-only { ... }
@media @tablet-up { ... }
@media @tablet-only { ... }
@media @desktop-up { ... }
@media @desktop-only { ... }
@media @desktop-large-up { ... }
@media @desktop-large-only { ... }
@media @desktop-xlarge-up { ... }
@media @desktop-xlarge-only { ... }
@media @desktop-xxlarge-up { ... }
*/
.navigation {
  min-height: 50px;
}
@media screen and (min-width: 1025px) {
  .navigation {
    min-height: 50px;
  }
}
.navigation .navbar {
  z-index: 10;
  border-radius: 0;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 1025px) {
  .navigation .navbar {
    box-shadow: 0 0.2em 0.5em gray;
    border: none;
    border-radius: 0.5em;
  }
}
@media screen and (max-width: 1025px) {
  .navigation .navbar-dynamic.visible .container {
    display: none;
  }
}
.navigation .dropdown-menu.visible {
  display: block;
  border: none !important;
}
.navigation .dropdown-menu.on li {
  margin-left: 0;
}
.navigation .navbar-default {
  background-color: #fff;
  background-image: unset;
  border: unset;
  font-family: 'overlock-bold';
  box-shadow: none;
}
.mobile-navigation-conteiner {
  position: relative;
}
.mobile-navigation-conteiner * {
  box-sizing: initial;
}
.mobile-navigation-conteiner ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mobile-navigation-conteiner .main-menu:after {
  content: '';
  background: url('/images/rebranding/ColourSideSplashSmall.png') no-repeat right;
  background-size: contain;
  width: 173px;
  height: 378px;
  right: 0;
  bottom: 0;
  position: absolute;
}
.mobile-navigation-conteiner #menu {
  font-family: overlock-regular;
  position: relative;
  min-height: 48px;
  max-width: 1024px;
  z-index: 999;
}
.mobile-navigation-conteiner #menu .main-menu {
  z-index: 2;
  top: 47px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-delay: 1s;
  background-color: #fff;
  transition: background-color 0.45s linear, max-height 0.35s ease-in 200ms;
  max-height: 1px;
  overflow-x: hidden;
  width: 100%;
}
.mobile-navigation-conteiner #menu .main-menu .menu,
.mobile-navigation-conteiner #menu .main-menu .menu-title {
  font-size: 1.8em;
  padding-left: 30px;
}
.mobile-navigation-conteiner #menu .main-menu .menu {
  margin-top: 80px;
  margin-bottom: 0;
}
.mobile-navigation-conteiner #menu .main-menu .menu-title {
  margin-bottom: 30px;
  font-family: overlock-bold;
}
.mobile-navigation-conteiner #menu input[type='checkbox'],
.mobile-navigation-conteiner #menu ul span.drop-icon {
  display: none;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu {
  visibility: visible;
  max-height: 100%;
  background-color: #000;
  transition-delay: 1s;
  transition: background-color 0.35s linear, max-height 0.35s ease-in-out 200ms;
}
.mobile-navigation-conteiner #tm:checked + #toggle-menu {
  color: #fff;
  background-color: #000;
  border-bottom: 0;
  transition-delay: 1s;
  transition: background-color 0.45s linear, max-height 0.35s ease-in 200ms;
}
.mobile-navigation-conteiner #menu li,
.mobile-navigation-conteiner #menu a {
  color: #fff;
  overflow: hidden;
}
.mobile-navigation-conteiner #toggle-menu {
  color: #000;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  line-height: 48px;
  height: 55px;
  transition-delay: 1s;
  background-color: #fff;
  transition: background-color 0.45s linear, max-height 0.35s ease-in 200ms;
  display: flex;
  flex-direction: column;
}
.mobile-navigation-conteiner #menu a {
  transition: all 0.125s ease-in-out;
  -webkit-transition: all 0.125s ease-in-out;
  text-decoration: none;
  overflow: hidden;
  font-family: gafata-regular;
  font-size: 1.2em;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
  direction: ltr;
  position: relative;
  width: 100%;
}
.mobile-navigation-conteiner #menu a:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.mobile-navigation-conteiner .main-menu > li {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: scale(1.1) translateY(-24px);
  transform: scale(1.1) translateY(-24px);
  -webkit-transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  padding-left: 60px;
  margin-bottom: 20px;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(9) {
  -webkit-transition-delay: 450ms, 450ms;
  transition-delay: 450ms, 450ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(8) {
  -webkit-transition-delay: 400ms, 400ms;
  transition-delay: 400ms, 400ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(7) {
  -webkit-transition-delay: 350ms, 350ms;
  transition-delay: 350ms, 350ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(6) {
  -webkit-transition-delay: 300ms, 300ms;
  transition-delay: 300ms, 300ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(5) {
  -webkit-transition-delay: 250ms, 250ms;
  transition-delay: 250ms, 250ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(4) {
  -webkit-transition-delay: 200ms, 200ms;
  transition-delay: 200ms, 200ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(3) {
  -webkit-transition-delay: 150ms, 150ms;
  transition-delay: 150ms, 150ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(2) {
  -webkit-transition-delay: 100ms, 100ms;
  transition-delay: 100ms, 100ms;
}
.mobile-navigation-conteiner .main-menu > li:nth-child(1) {
  -webkit-transition-delay: 50ms, 50ms;
  transition-delay: 50ms, 50ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li,
.mobile-navigation-conteiner #tm:target ~ .main-menu > li {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: none;
  transform: none;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(1),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(1) {
  -webkit-transition-delay: 250ms, 250ms;
  transition-delay: 250ms, 250ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(2),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(2) {
  -webkit-transition-delay: 300ms, 300ms;
  transition-delay: 300ms, 300ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(3),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(3) {
  -webkit-transition-delay: 350ms, 350ms;
  transition-delay: 350ms, 350ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(4),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(4) {
  -webkit-transition-delay: 400ms, 400ms;
  transition-delay: 400ms, 400ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(5),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(5) {
  -webkit-transition-delay: 450ms, 450ms;
  transition-delay: 450ms, 450ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(6),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(6) {
  -webkit-transition-delay: 500ms, 500ms;
  transition-delay: 500ms, 500ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(7),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(7) {
  -webkit-transition-delay: 550ms, 550ms;
  transition-delay: 550ms, 550ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(8),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(8) {
  -webkit-transition-delay: 600ms, 600ms;
  transition-delay: 600ms, 600ms;
}
.mobile-navigation-conteiner #tm:checked ~ .main-menu > li:nth-child(9),
.mobile-navigation-conteiner #tm:target ~ .main-menu > li:nth-child(9) {
  -webkit-transition-delay: 650ms, 650ms;
  transition-delay: 650ms, 650ms;
}
.mobile-navigation-conteiner #toggle-menu .menu-toggle {
  z-index: 2;
  padding: 5px 0;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.1em;
}
.mobile-navigation-conteiner #toggle-menu .menu-toggle a {
  color: #000;
  font-family: overlock-regular;
}
.mobile-navigation-conteiner #toggle-menu .menu-toggle img {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mobile-navigation-conteiner #toggle-menu .menu-toggle span {
  font-size: 1.1em;
}
.navigation .navbar-default.navbar-dynamic {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}
.navigation-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  text-decoration: none;
  position: relative;
}
.navigation-bar .navigation-bar-link {
  color: #000;
  font-size: 1.2em;
  padding: 15px 15px;
  text-decoration: none;
  cursor: pointer;
}
.navigation-bar .navigation-bar-link-image {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  vertical-align: top;
  border-radius: 50%;
}
.navigation-bar .navigation-bar-link.active {
  color: #0e88a2;
}
.navigation-bar .navigation-bar-link.disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.navigation-bar .settings-list {
  margin-right: 40px;
  position: absolute;
  top: 10px;
  right: 0;
}
.navigation-bar ul {
  padding: 0;
  margin: 0;
}
.navigation-bar li {
  list-style-type: none;
}
.navigation-bar .username {
  font-size: 18px;
}
.dropdown-menu.dropdown-settings a {
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
.dropdown-menu.dropdown-settings a:focus,
.dropdown-menu.dropdown-settings a:hover {
  outline: none;
  background-image: unset;
}
.isScrolling {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
}
.tm-menu {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  background-color: #fff;
}
.checked-tm-menu {
  background-color: transparent;
}
