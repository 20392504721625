/* USAGE:
@import '... /responsive.less';
@media @phone-up { ... }
@media @phone-only { ... }
@media @tablet-up { ... }
@media @tablet-only { ... }
@media @desktop-up { ... }
@media @desktop-only { ... }
@media @desktop-large-up { ... }
@media @desktop-large-only { ... }
@media @desktop-xlarge-up { ... }
@media @desktop-xlarge-only { ... }
@media @desktop-xxlarge-up { ... }
*/
@font-face {
  font-family: 'GoudyOldStyleT-Bold';
  font-display: block;
  src: local('GoudyOldStyleT-Bold'), url('/fonts/GoudyOldStyleT-Bold/GoudyOldStyleT-Bold.woff') format('woff'), url('/fonts/GoudyOldStyleT-Bold/GoudyOldStyleT-Bold.eot') format('embedded-opentype'), url('/fonts/GoudyOldStyleT-Bold/GoudyOldStyleT-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GoudyOldStyleItalic';
  font-display: block;
  src: local('GoudyOldStyleItalic'), url('/fonts/GoudyOldStyleItalic/GoudyOldStyleItalic.woff') format('woff'), url('/fonts/GoudyOldStyleItalic/GoudyOldStyleItalic.eot') format('embedded-opentype'), url('/fonts/GoudyOldStyleItalic/GoudyOldStyleItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gartonmedium';
  font-display: block;
  src: local('gartonmedium'), url('/fonts/gartonmedium/gartonmedium.woff') format('woff'), url('/fonts/gartonmedium/gartonmedium.eot') format('embedded-opentype'), url('/fonts/gartonmedium/gartonmedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubiel';
  src: url('/fonts/Dubiel.ttf') format('truetype');
}
@font-face {
  font-family: 'dubielitalic-webfont';
  src: url('/fonts/dubielitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'gafata-regular';
  src: url('/fonts/Gafata/Gafata-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'overlock-regular';
  src: url('/fonts/Overlock/Overlock-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'overlock-bold';
  src: url('/fonts/Overlock/Overlock-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'overlock-black';
  src: url('/fonts/Overlock/Overlock-Black.ttf') format('truetype');
}
html,
body {
  min-height: 100%;
  height: 100%;
  font-size: 16px;
}
#root,
.root,
.jumbotron {
  height: 100%;
}
.container {
  height: 100%;
}
.full-height {
  height: 100%;
}
.overlock-bold {
  font-family: overlock-bold;
}
body {
  background: url('/images/rebranding/main-bg-test.png');
  background-size: 100%;
  background-position: 0 100px;
  background-repeat: no-repeat;
  overflow-x: hidden;
}
label,
input {
  font-family: overlock-regular;
}
.panel {
  box-shadow: none;
  border: none;
}
h1,
h2,
h3,
h4,
h5,
p,
.caption,
.discover,
.comment-form-item {
  font-family: overlock-regular;
}
a,
a:hover,
a:focus {
  text-decoration: none;
}
.inline-block {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.root.landing-page {
  height: 100vh;
  overflow: hidden;
}
.root .data-block,
.root .profile {
  background-color: white;
  min-height: 500px;
}
.root .flex-center,
.root .flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.root .flex-center.options-container,
.root .flex-column.options-container {
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .root .flex-center.options-container,
  .root .flex-column.options-container {
    flex-wrap: nowrap;
    height: 65%;
  }
}
.root .flex-column {
  flex-direction: column;
}
.root .flex-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.root .flex-direction-column {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 600px) {
  .root .flex-column-sm {
    flex-direction: column;
  }
}
.root .mt-auto {
  margin-top: auto;
}
.root .panel-primary {
  padding: 5em 0;
  border: none;
}
.root .panel-primary.spacing-bottom {
  padding-bottom: 15rem;
}
li .filter-category.active {
  color: red !important;
}
.modal-dialog {
  margin-top: 4em;
}
.modal-dialog .old-price {
  color: gray;
}
ul.notifications {
  position: fixed;
}
/*Responsive properties*/
@media screen and (max-width: 767px) {
  .arrow-left,
  .arrow-right {
    width: 43px;
  }
}
/*styles for search-source*/
.notifications {
  font-size: 19px;
  font-family: 'Open Sans', sans-serif;
}
.goudy.arrow {
  position: absolute;
  top: calc(50% - .7em);
  right: 20px;
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  animation: move 2s infinite;
  font-size: 0.7em;
}
@keyframes move {
  0% {
    right: 15px;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 15px;
  }
}
@-webkit-keyframes move {
  0% {
    right: 15px;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 15px;
  }
}
@-moz-keyframes move {
  0% {
    right: 15px;
  }
  50% {
    right: 5px;
  }
  100% {
    right: 15px;
  }
}
.center-content {
  display: flex;
  justify-content: center;
}
.right-content {
  display: flex;
  justify-content: flex-end;
}
.close-modal {
  position: absolute;
  top: 0px;
  right: 5px;
  font-family: sans-serif;
  font-size: 22px;
  color: #000;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}
.mt-30 {
  margin-top: 30px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.raised-card {
  position: relative;
  width: 300px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
}
.raised-card .btn-block:not(:last-child) {
  margin-bottom: 20px;
}
.raised-card .btn-dark {
  color: #fff;
  background-color: black;
  border-color: #060606;
  font-size: 18px;
  line-height: 25px;
}
.common-modal label {
  text-transform: uppercase;
  color: #44444f;
  font-family: overlock-bold;
  margin-bottom: 15px;
}
.common-modal .modal-header {
  border-bottom: 0px;
  padding-left: 3.1vw;
  padding-right: 3.1vw;
  margin-bottom: 7vh;
  margin-top: 1vh;
}
.common-modal .modal-header .close {
  color: #000;
  opacity: 1;
  font-weight: normal;
  font-size: 40px;
}
.common-modal .modal-header .close:focus {
  outline: unset;
}
.common-modal .modal-title {
  font-family: overlock-black;
  font-size: 28px;
  color: #000;
}
.common-modal .modal-footer {
  border-top: 0;
  padding-left: 3.1vw;
  padding-right: 3.1vw;
  margin-bottom: 5vh;
}
.common-modal .modal-body {
  padding-left: 3.1vw;
  padding-right: 3.1vw;
}
.common-modal .modal-body video {
  width: 100%;
  background-color: #000;
}
.go-top {
  font-family: overlock-bold;
  cursor: pointer;
  background: #000 none repeat scroll 0 0;
  bottom: 20px;
  opacity: 0;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  right: -60px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  z-index: 100;
  text-decoration: none;
  font-size: 1.8em;
  border: 2px solid #000;
}
.go-top:hover {
  background: white;
  color: black;
  text-decoration: none;
}
.go-top.on {
  opacity: 1;
  right: 10px;
}
.go-top.off {
  opacity: 0;
  right: -60px;
}
