/* USAGE:
@import '... /responsive.less';
@media @phone-up { ... }
@media @phone-only { ... }
@media @tablet-up { ... }
@media @tablet-only { ... }
@media @desktop-up { ... }
@media @desktop-only { ... }
@media @desktop-large-up { ... }
@media @desktop-large-only { ... }
@media @desktop-xlarge-up { ... }
@media @desktop-xlarge-only { ... }
@media @desktop-xxlarge-up { ... }
*/
.taod-panel {
  width: 100%;
}
.taod-panel .taod-panel-content {
  height: 100%;
  position: relative;
}
.taod-panel .panel-primary {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 4rem;
  margin: 0;
}
@media only screen and (max-width: 767px), only screen and (max-height: 500px) {
  .taod-panel .panel-primary {
    min-height: 400px;
  }
}
@media only screen and (min-width: 768px) {
  .taod-panel .panel-primary {
    padding-bottom: 20rem;
  }
  .taod-panel .panel-primary .blog-h1 {
    padding: 0 10rem;
  }
}
.taod-panel .panel-primary > * {
  position: relative;
}
.taod-panel.spacing-top-bottom .taod-panel-content {
  padding: 5rem 0 10rem 0;
}
.dialog-open .taod-panel {
  z-index: initial;
}
.dialog-open .taod-panel .taod-panel-content {
  z-index: initial;
}
