/* USAGE:
@import '... /responsive.less';
@media @phone-up { ... }
@media @phone-only { ... }
@media @tablet-up { ... }
@media @tablet-only { ... }
@media @desktop-up { ... }
@media @desktop-only { ... }
@media @desktop-large-up { ... }
@media @desktop-large-only { ... }
@media @desktop-xlarge-up { ... }
@media @desktop-xlarge-only { ... }
@media @desktop-xxlarge-up { ... }
*/
.persona-header {
  margin-bottom: 3rem;
}
.persona-header .page-name {
  text-align: center;
  font-size: 2.25em;
}
.persona-header .options-blocks {
  text-align: center;
  padding: 30px 0;
  font-size: 2.6em;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 767px), only screen and (max-height: 500px) {
  .persona-header .options-blocks {
    font-size: 2em;
    padding: 20px;
  }
}
.persona-header .options-blocks .select-wrapper {
  position: relative;
  display: inline-flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
  margin-bottom: 1rem;
  flex-direction: column;
}
@media only screen and (max-width: 767px), only screen and (max-height: 500px) {
  .persona-header .options-blocks .select-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 3rem;
  }
}
.persona-header .options-blocks .select-wrapper .glyphicon-triangle-bottom {
  position: absolute;
  right: 30px;
  top: 16px;
}
@media only screen and (min-width: 768px) {
  .persona-header .options-blocks .select-wrapper .glyphicon-triangle-bottom {
    right: 65px;
  }
}
@media only screen and (max-width: 767px), only screen and (max-height: 500px) {
  .persona-header .options-blocks .select-wrapper span {
    min-height: auto;
  }
}
.persona-header .options-blocks .select-wrapper:last-child:after {
  content: '';
  display: block;
  width: 1px;
  border: none;
}
.persona-header .links-personas {
  text-align: center;
  padding: 0 0 23px 0;
}
.persona-header .links-personas .retake-link {
  margin: 0 0 20px 0;
  font-family: overlock-regular;
}
.data-block {
  background: white;
}
.data-block .content-data {
  padding: 0 60px;
}
.data-block .content-head {
  width: 100%;
  text-align: center;
  padding: 25px;
}
.data-block .drop-block {
  position: relative;
}
.data-block .drop-block .btn-group {
  cursor: pointer;
}
.data-block .drop-block .dropdown-activator {
  font-size: 2.25em;
  font-family: overlock-bold;
  margin-right: 0.3em;
}
.data-block .drop-block .dropdown-item {
  padding: 0;
}
.data-block .drop-block .dropdown-item li {
  font-size: 2.5em;
  border-bottom: solid 1px;
  border-right: 0;
  border-left: 0;
  cursor: pointer;
  font-family: overlock-regular;
}
.data-block .drop-block .dropdown-item li.dropdown-header {
  font-family: overlock-bold;
  background-color: #000000b0;
  color: #fff;
}
.data-block .drop-block .dropdown-item li:first-child {
  border-top: 0;
}
.data-block .drop-block .dropdown-item li:hover:not(.dropdown-header),
.data-block .drop-block .dropdown-item li.picked {
  background: black;
  color: white;
}
.data-block .drop-block .dropdown-item li a:hover,
.data-block .drop-block .dropdown-item li a.picked {
  background: black;
  color: white;
}
@media only screen and (max-width: 767px), only screen and (max-height: 500px) {
  .persona-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
.edit-attributes-title {
  font-size: 2.5em;
  font-family: overlock-bold;
  margin-bottom: 20px;
}
